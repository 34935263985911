import React from "react"
import { rhythm } from '../utils/typography'

export default () => (
  <div
  style={{
    marginLeft: "155px",
    float : "left"
  }}
>
  <p>
    Tech Listicles by Makers.
  </p>
</div>
)