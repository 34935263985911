import React from "react"
import { rhythm } from '../utils/typography'

export default () => (
  <div
  style={{
    marginLeft: "50px",
    float : "left"
  }}
>
  <p>
    Tech Listicles by Makers. <a href="/about/">About</a> and <a href="https://github.com/oneismine/oneismine.github.io/blob/source/README.md">Contribute</a> and <a href="https://twitter.com/oneisminecom">Twitter</a>.
  </p>
</div>
)